import { createRouter, createWebHashHistory } from "vue-router";
import Main from "@/views/Main.vue";
import Home from "@/views/Home.vue";

const routes = [
    // Index
    { path: "/", component: Home, name: "Home" },
    // Projects
    { path: "/project/", component: Main },
    { path: "/projects/:project", component: Main },
    // Shop
    { path: "/shop/", component: Main },
    { path: "/shop/:shop", component: Main },
    // Form
    { path: "/form/", component: Main },
    { path: "/forms/:form", component: Main },
    // FAQ
    { path: "/faq/", component: Main },
    { path: "/faq/:faq", component: Main },
    // About
    { path: "/about/", component: Main },
    // Ressources
    { path: "/ressources/", component: Main },
    // Members
    { path: "/members/", component: Main },
    { path: "/members/:member", component: Main },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
