<template>
    <a id="Badge" :href="`mailto:${mail}`">
        <div class="background" :style="{ backgroundImage: 'url(' + picto + ')' }"></div>
        <div class="text">
            <div class="textGroup">
                <h1>{{ en }}</h1>
                <h2 class="item">{{ de }}</h2>
            </div>
        </div>
    </a>
</template>

<script>
import "@/styles/badge.scss";

export default {
    name: "Badge",
    components: {},
    props: {
        en: {
            type: String,
            default: "",
        },
        de: {
            type: String,
            default: "",
        },
        picto: {
            type: String,
            default: "",
        },
        mail: {
            type: String,
            default: "",
        },
    },
};
</script>
