<template>
    <!-- <Navbar /> -->
    <router-view class="page"></router-view>
    <Footer />
    <News />
</template>

<script>
import "@/styles/const.scss";

// import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import News from "../components/News.vue";

export default {
    name: "Main",
    components: {
        // Navbar,
        Footer,
        News,
    },
    data() {
        return {};
    },
    // watch: {
    //     $route(to) {
    //         this.about = to.params.page == "about";
    //     },
    // },
    mounted() {},
    methods: {
        go(to) {
            this.$router.replace(to);
        },
    },
};
</script>
