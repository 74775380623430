<template>
    <div id="Home">
        <div id="Overview">
            <div class="text">
                <div class="status">
                    <img class="siteName" src="@/assets/images/gilbard.svg" />
                    <!-- <Schedule /> -->
                    <a class="informations" href="https://goo.gl/maps/e4YKyQDFpocqeihx6">
                        <p class="address">Rue de Liverpoolstraat 77</p>
                        <p class="address">1070 Anderlecht</p>
                    </a>
                </div>
                <p class="description">
                    Gilbard est un collectif basé à Anderlecht qui travaille à partir de matériaux de réemploi pour
                    concevoir des projets locaux autour de l’art et du design.
                </p>
                <p class="description border little">
                    Gilbard is een collectief gevestigd in Anderlecht dat werkt met hergebruikte materialen om lokale
                    projecten rond kunst en design te creëren.
                </p>
            </div>
            <div class="image"><img src="@/assets/images/wired.jpg" /></div>
        </div>
        <div id="Badges">
            <Badge
                en="RECUPERATION DE MATERIAUX"
                de="RECUPEREREN VAN MATERIALEN"
                :picto="require('./../assets/images/picto/tools.png')"
                mail="stock@gilbard.be"
            />
            <Badge
                en="ACTIVITES DE QUARTIER"
                de="ACTIVITEITEN IN DE BUURT"
                :picto="require('./../assets/images/picto/map.png')"
                mail="1070@gilbard.be"
            />
            <Badge
                en="EXPERIMENTATION, ART ET DESIGN"
                de="EXPERIMENTEN IN KUNST EN DESIGN"
                :picto="require('./../assets/images/picto/plan.png')"
                mail="orga@gilbard.be"
            />
            <Badge
                en="COMMANDES ET PARTENARIATS"
                de="ORDERS EN PARTNERSCHAPPEN"
                :picto="require('./../assets/images/picto/handbook.png')"
                mail="commandes@gilbard.be"
            />
            <Badge
                en="MUTUALISATION"
                de="MUTUALISATIE"
                :picto="require('./../assets/images/picto/g.png')"
                mail="info@gilbard.be"
            />
        </div>
    </div>
</template>

<script>
import "@/styles/home.scss";
// import Schedule from "../components/Schedule.vue";
import Badge from "../components/Badge.vue";

export default {
    name: "Main",
    components: {
        // Schedule,
        Badge,
    },
    data() {
        return {
            thanks: [
                "Art et Marges",
                "Bozar",
                "Bx elles",
                "Casablanco",
                "CLT Brussel",
                "Coopcity",
                "Cultureghem",
                "ETS Vanzurpele",
                "Fédération des Récupérathèques",
                "iMAL",
                "In Limbo",
                "La Communa",
                "Lochten & Germeau",
                "Microfactory",
                "MJ Rezolution",
                "Pottal ASBL",
                "Repair Mobile-Recyclart",
                "Solidarcité",
                "Super-Structure",
            ],
        };
    },
    // mounted() {},
    // methods: {},
};
</script>
