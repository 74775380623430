<template>
    <div id="News">
        <Vue3Marquee :clone="true" :duration="20">
            <span>{{ news.fr }}</span>
            <span>&nbsp;-&nbsp;</span>
            <span>{{ news.de }}</span>
            <span>&nbsp;-&nbsp;</span>
        </Vue3Marquee>
    </div>
</template>

<script>
import "@/styles/news.scss";
import { Vue3Marquee } from "vue3-marquee";
import "vue3-marquee/dist/style.css";

export default {
    name: "Footer",
    components: {
        Vue3Marquee,
    },
    data() {
        return {
            news: {
                fr: "Permanence vendredi 13h30 - 18h00",
                de: "Permanentie vrijdag, 13h30 - 18h00",
            },
        };
    },
};
</script>
