<template>
    <footer id="Footer">
        <div class="links">
            <a href="./"><img class="item logo" src="@/assets/images/gobelin.svg" /></a>
            <h1 class="item">
                <a href="https://www.instagram.com/gilbard.e.s/">
                    <span>@gilbard.e.s</span>
                </a>
            </h1>
            <h1 class="item">
                <a href="tel:+32471926104">
                    <span>+32 471 92 61 04</span>
                </a>
            </h1>
            <h1 class="item"><a href="mailto:info@gilbard.be">info@gilbard.be</a></h1>
        </div>
    </footer>
</template>

<script>
import "@/styles/footer.scss";

export default {
    name: "Footer",
};
</script>
